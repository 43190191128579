<template>
  <v-card class="elevation-0 price-card">
    <v-card-text class="">
      <div class="primary--text border-maxLightGray-b pb-2">{{ name }}</div>
    </v-card-text>
    <v-card-text class="pt-0">
      <div class="d-flex align-center">
        <span class="mr-2">新台幣</span>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="price"
          class="mr-2"
        ></v-text-field>
        <span>{{ unit }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "@/components/eaglePlugin/ItemMixins.js";
export default {
  mixins: [mixins],
  data: () => ({
    name: null,
    price: 0,
  }),
  computed: {
    shipping_unit() {
      return this.value.shipping_unit 
    },
    unit() {
      return `元/${this.shipping_unit}`;
    },
  },
};
</script>
<style lang="sass">
  .price-card
    border-radius: 5px !important
    border: 1px solid #ECECEC !important

</style>